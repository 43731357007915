body.pos-fixed {
  overflow: hidden;
}
body {
  min-width: 1024px;
  width: auto !important;
  margin: 0;
  font-family: "Raleway", sans-serif;
}
code {
  font-family: "Raleway", sans-serif;
}
.ant-popconfirm {
  .ant-popover-inner {
    min-width: 350px;
  }
}

.ant-popover-inner {
  width: fit-content;
}

.ant-dropdown-menu {
  min-width: 120px;
}

.modalStyle .ant-modal-content {
  padding: 0px !important;
  margin: 20px !important;
}
.progressBar.ant-progress .ant-progress-text {
  color: #ffffff;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/static/Raleway-Light.ttf") format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-Black.ttf") format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-BlackItalic.ttf") format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-BoldItalic.ttf") format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-ExtraBold.ttf") format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-ExtraBoldItalic.ttf")
      format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-ExtraLight.ttf") format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-ExtraLightItalic.ttf")
      format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-Light.ttf") format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-Medium.ttf") format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-Italic.ttf") format("ttf"),
    url("./assets/fonts/Raleway/static/Raleway-Bold.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

.deck-loader {
  position: absolute;
  background-color: #d7d7d7;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
