.playback {
  display: none !important;
}

.controls {
  display: none !important;
}

.reveal .slides {
  text-align: left !important;
}

.slides {
  transform: translate(-50%, -50%) scale(0.92) !important;
  height: 600px !important;
}
.reveal .slides > section.after-quiz {
  top: 90px !important;
}

.audio-player {
  background-color: #000;
  color: #fff;
  width: 100%;
  padding-right: 5px;
}

.audio-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audio-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.seek {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, #177a9c 0%, #fff 0%);
  cursor: pointer;
  transition: background 0.25s;
  position: absolute;
  left: 0px;
  bottom: 54px;
}

.seek:hover {
  opacity: 1;
}

.seek::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  background: #177a9c;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.seek::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #177a9c;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.seek::-ms-thumb {
  width: 12px;
  height: 12px;
  background: #177a9c;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.seek:focus {
  outline: none;
}

.seek::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.volume {
  -webkit-appearance: none;
  appearance: none;
  width: 100px;
  height: 5px;
  background: linear-gradient(to right, #177a9c 0%, #fff 0%);
  outline: none;
  transition: background 0.25s;
  border-radius: 5px;
}

.volume:hover {
  opacity: 1;
}

.volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  background: #177a9c;
  cursor: pointer;
  border-radius: 50%;
}

.volume::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: #177a9c;
  cursor: pointer;
  border-radius: 50%;
}

.volume::-ms-thumb {
  width: 10px;
  height: 10px;
  background: #177a9c;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.volume:focus {
  outline: none;
}

.volume::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.volume::-ms-fill-lower {
  background: #177a9c;
  border-radius: 5px;
}

.volume::-ms-fill-upper {
  background: #fff;
  border-radius: 5px;
}
